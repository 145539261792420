import React, { useContext, useState } from 'react';
import { getGelTokens } from "@tal-gel/theming";
import {
    GelRowLayout,
    GelSpinner,
    GelIcon,
    GelButton
} from "@tal-gel/components";
import Card from '../../../common/components/card';
import NotificationList from './notification-list';
import { ACTIVITY_TYPE, AdobeClickType, API, NotificationType } from '../../../constants/constants';
import SESSION_STORAGE_KEY from '../../../constants/storage.constant';
import { MARK_NOTIFICATION_READ } from '../../../graphql/queries/graphql-mutations';
import axios from 'axios';
import { AdobeAnalytics } from '../../../common/analytics/adobe-analytics';
import { useCookies } from 'react-cookie';
import { UserContext } from '../../../common/usercontext/user.context';
import { MemberNotification } from '../../../common/models/memberclaims';

interface NotificationCardProps {
    requirements: any[];
    isNotificationDataLoaded: Boolean;
    claimDetailsContentfulModel: any; 
    notifications: MemberNotification[];
}

const { global: { sizeBaseX20 } } = getGelTokens();

const NotificationCard = ({ requirements, isNotificationDataLoaded, claimDetailsContentfulModel, notifications }: NotificationCardProps) => {
    const [{ aid, rid, ssoid, tpid }] = useCookies(['aid', 'rid', 'ssoid', 'tpid']);
    const { contextData, setUserContext } = useContext(UserContext);
    const [notificationUpdating, setnotificationUpdating] = useState(false);

    const visibleRequirements = requirements.filter(({ type, completedDate }) =>
        [NotificationType.ProceesedPayment, NotificationType.Activity, NotificationType.FuturePayment]
            .includes(type) || !completedDate);
    
    const visibleNotifications = requirements
        .filter(({ id, type }) => notifications.some(({ notificationId }) => notificationId == id) &&
            type === NotificationType.ProceesedPayment)

    const clearNotifications = () => {
        AdobeAnalytics.PushClickEvent(tpid, "Close", AdobeClickType.Link)

        let notificationIdsToClear = visibleNotifications.map(({ id }) => id);

        if (notificationIdsToClear.length === 0)
            return;

        setnotificationUpdating(true);

        axios.post(API.BASEURL, {
            query: MARK_NOTIFICATION_READ,
            variables: {
                markNotificationRequest: {
                    notificationIds: notificationIdsToClear
                }
            }
        },
            {
                headers: {
                    'x-aid': aid,
                    'x-rid': rid,
                    ssoid,
                    tpid,
                    'sid': window.sessionStorage.getItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN)
                }
            })
            .then(async (response) => {
                var notificationsResponse = response?.data?.data?.markNotificationsRead?.list || [];
                clearNotificationsFromCard(notificationsResponse);

                setnotificationUpdating(false);
            })
    }

    const clearNotificationsFromCard = (notifications: MemberNotification[]) => {
        notifications.forEach(notification => {
          let claim = contextData.claims.find(
            (claim) => claim.claimId === notification?.claimId
          );
      
          if (claim) {
            // Remove requirement if it matches the notificationId
            const index = claim.requirements.findIndex(
              (req) => req.id === notification?.notificationId
            );
            if (index >= 0) {
              claim.requirements.splice(index, 1);
            }
      
            // Unhide activities that match the notification's sourceId
            claim.activities
              .filter((at) => at.claimPaymentId === notification.sourceId)
              .forEach((activity) => {
                activity.hideActivity = false;
              });
          }
        });
      
        // Update context once after processing all notifications
        setUserContext({
          claims: contextData.claims,
          yourDetails: contextData.yourDetails,
          memberDetails: contextData.memberDetails,
        });
      };
      

    return (<Card
        key={crypto.randomUUID()}
        title={claimDetailsContentfulModel?.notificationsCardHeader}
        icon={"InfoCircle"}
        subTitle={claimDetailsContentfulModel?.notificationCardHeaderDescription}
        showFooter={false}
    >
        {(!isNotificationDataLoaded) &&
            <div style={{ height: sizeBaseX20 }}>
                <GelSpinner medium overlay />
            </div>}

        {notificationUpdating && <GelSpinner medium overlay />}

        {(isNotificationDataLoaded) && (
            <>
                {visibleNotifications.length > 0 && 
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div style={{ paddingRight: `10px`, paddingTop: '5px', paddingBottom: '5px' }}>
                        <GelButton
                            tertiary
                            small
                            onClick={clearNotifications}
                        >
                            Mark all notifications as read
                        </GelButton>
                    </div>
                </div>
                }
                <NotificationList requirements={visibleRequirements} />
            </>
        )}
    </Card>);
}

export default NotificationCard;