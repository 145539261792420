import React, {useEffect, useState} from "react";
import {useContext} from "react";
import {GelPageLayoutContext, GelScreenDetectorContext, GelSpinner} from "@tal-gel/components";
import SideNav from "./side-nav";
import MiniSideNav from "./side-nav-mini";
import TopNavMob from "./top-nav-mob";
import MiniTopNavMob from "./top-nav-mob-mini";
import {useLocation} from "react-router";
import {UserContext} from "../../common/usercontext/user.context";
import {useGetTrayMenuItemsContent} from "../../common/api/graphQLDataFetch";

interface ContextNavOpen {
    isSideNavOpen: boolean;
    openSideNav: (navopen: boolean) => void;
}

// TODO: refactor the graph ql request
const PageNavigation = () => {
    const {isSideNavOpen, openSideNav} = useContext<ContextNavOpen>(GelPageLayoutContext);
    const {screen, isXsScreen} = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const {contextData} = useContext(UserContext);
    const location = useLocation();
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);

    const {data, isLoading} = useGetTrayMenuItemsContent();

    let isMemberClaimAvailble = true;

    if (contextData.claims?.length === 0 && contextData.memberDetails?.memberNumber) {
        isMemberClaimAvailble = false;
    }

    const toggleMenu = () => {
        openSideNav(!isSideNavOpen);
    };

    // Update selected menu item when the route changes
    useEffect(() => {
        if (!data) return;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const menuItem = data?.menuItems.find((item: any) => item?.link === location.pathname.split("/")[1]);
        setSelectedMenuItem(menuItem);
    }, [location.pathname, data]); // This will re-run when location.pathname or contentfulData changes

    if (isLoading) {
        return <GelSpinner medium overlay />;
    }

    if (contextData.memberDetails?.memberNumber && isMemberClaimAvailble && data) {
        if (isXsScreen(screen)) {
            const MobileNavBar = isSideNavOpen ? TopNavMob : MiniTopNavMob;
            return <MobileNavBar onMenuHideClick={toggleMenu} textData={data} activeMenuItem={selectedMenuItem} />;
        } else {
            const DesktopNavBar = isSideNavOpen ? SideNav : MiniSideNav;

            return <DesktopNavBar onMenuHideClick={toggleMenu} textData={data} activeMenuItem={selectedMenuItem} />;
        }
    }
};

export default PageNavigation;
