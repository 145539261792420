export const GET_LOGINPAGE_CONTENT_QUERY = `
    query GetMmcLoginPageCollectionForFund($fundName: [String]) { 
        items: mmcLoginPageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
            items {
                    headerText
                    subText
                    signUpText
                    signUpLink
                    loginButtonText
                    emailText
                    superFundText
                    passwordText
                    forgotPasswordText
                    forgotPasswordLink
                    errorMessageContent
                }
            }
        }
`;

export const GET_SIGNUPPAGE_CONTENT_QUERY = `
    query GetMmcSignUpPageCollectionForFund($fundName: [String]) { 
        items: mmcSignUpPageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
            items {
                    headerText
                    subText
                    signInText
                    signInLink
                    continueButtonText
                    superFundText
                    memberNumberText
                    dateOfBirthText
                    captchaText
                    errorMessageContent
                }
            }
        }
`;

export const GET_CONFIRM_YOUR_DETAILS_CONTENT_QUERY = `
    query GetMmcConfirmDetailsPageCollectionForFund($fundName: [String]) { 
        items: mmcConfirmDetailsPageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
            items {
                    headerText
                    subText
                    continueButtonText
                    superFundText
                    memberNumberText
                    dateOfBirthText
                    captchaText
                    mobileNumberText
                    updateMobileHeaderText
                    updateMobileSubText
                    errorMessageContent
                }
            }
        }
`;

export const GET_FORGOTPASSWORD_CONTENT_QUERY = `
    query GetMmcForgotPasswordPageCollectionForFund($fundName: [String]) { 
        items: mmcForgotPasswordPageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
            items {
                    headerText
                    subText
                    signInText
                    signInLink
                    continueButtonText
                    superFundText
                    emailText
                    captchaText
                    errorMessageContent
                }
            }
        }
`;

export const GET_RESETPASSWORD_CONTENT_QUERY = `
    query GetMmcResetPasswordPageCollectionForFund($fundName: [String]) { 
        items: mmcPasswordResetPageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
            items {
                    headerText
                    headerSubtext
                    newPassword
                    confirmPassword
                    passwordRequirementsHeader
                    passwordRequirementsList
                    buttonText
                    errorMessageContent
                }
            }
        }
`;

export const GET_TWOFACTORPAGE_CONTENT_QUERY = `
    query GetMmcTwoFactorPageCollectionForFund($fundName: [String]) { 
        items: mmcTwoFactorPageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
            items {
                    headerText
                    subText
                    verifyButtonText
                    verificationCodeText
                    resendCodeText
                    didNotReceiveCodeText
                    resetMobileLink
                    notYourMobileText
                    errorMessageContent
                }
            }
        }
`;

export const GET_ACTIVATEPAGE_CONTENT_QUERY = `
    query GetMmcActivatePageCollectionForFund($fundName: [String]) { 
        items: mmcActivatePageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
            items {
                    headerText
                    subText                    
                    signInText
                    signInLink
                    passwordText
                    confirmPasswordText
                    activateButtonText
                    errorMessageContent
                    passwordRequirementsHeader
                    passwordRequirementsList
                    passwordRequirementsSubtext
                }
            }
        }
`;

export const GET_HOMEPAGE_CONTENT_QUERY = `
    query GetmmcDashboardPageCollectionForFund($fundName: [String]) { 
        items: mmcDashboardPageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
            items {
                headerText
                appTitle
                appTitleDescription
                subTitle
                buttonText
                benefitType
                requirementCompletedStatus
                thirdPartyRequirementStatus
                paymentMessage
                requirementInCompleteStatus
                requirementReviewStatus
                uploadDocumentText
                uploadDocumentDescriptor
                alertDetails
                }
            }
        }
`;

export const GET_CONFIRM_MOBILE_PAGE_CONTENT_QUERY = `
query GetMmcConfirmMobilePageCollectionForFund($fundName: [String]) { 
    items: mmcConfirmMobilePageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
        items {
                headerText 
                subText                   
                resetMobileText
                resetMobileLink
                confirmMobileDescText
                confirmMobileText
                confirmButtonText
            }
        }
    }
`;

export const GET_UPDATE_MOBILE_PAGE_CONTENT_QUERY = `
query GetMmcUpdateMobilePageCollectionForFund($fundName: [String]) { 
    mmcUpdateMobilePageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
        items {
                headerText   
                updateMobileText
                continueButtonText
                errorMessageContent
            }
        }
    }
`;

export const GET_TRAYMENU_ITEMS_CONTENT_QUERY = `
query GetmmcTrayMenuItemsCollectionForFund($fundName: [String]) { 
    items: mmcTrayMenuItemsCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
        items {
                menuItems
                menuHeader                  
            }
        }
    }
`;

export const GET_CLAIMDETAILS_CONTENT_QUERY = `
    query GetmmcClaimDetailsCollectionForFund($fundName: [String]) { 
        items: mmcClaimDetailsCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
            items {
                selectFiles
                otherDocumentText
                dragFiles
                supportedFileText
                maximumFileSize
                benefitAmount
                waitingPeriod
                benefitPeriod
                policyOwner
                policyNumber
                lifeInsured
                typeOfBenefit
                caseManager
                defaultCaseManager
                validationErrors
                headerText
                headerTextDescription
                notificationsCardHeader
                notificationCardHeaderDescription
                nextFuturePaymentHeader
                nextFuturePaymentBody
                documentsToStart
                maxFileSize
                viewSupportedTypes
                paymentMadeHeader
                claimsHistoryCardHeader
                claimsHistoryCardHeaderDescription
                requirementRaisedText
                requirementRaisedMessage
                requirementCompletedText
                requirementCompletedMessage
                paymentText
                paymentMessage
                tfnButtontext
                eftButtonText
                otherDocumentsCardHeaderDescription
                otherDocumentsCardHeader
                otherDocumentsAccordionTitle
                historyLoadMoreButtonText
                documentClaimNotifiedText
                requirementInReview
                requirementRequired
                detailsHeader
                disclaimer
                claimApprovedHeader
                claimHistoryApprovedDescription
                claimHistoryStartedDescription
                claimHistoryRefferedToDescription
                }
            }
        }
`;

export const GET_YOUR_DETAILS_PAGE_CONTENT_QUERY = `
query GetmmcYourDetailsPageCollectionForFund($fundName: [String]) { 
    items: mmcYourDetailsPageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
        items {
            insuredNameText
            dateofbirth
            email
            contactNumber
            address
            title
            subtitle
            headerText
            headerDescription
            contactTitle
            contactSubtext
            notificationPreferenceTitle
            notificationPreferenceSubTitle
            notificationPreferenceButtonText
            notificationPreferenceTooltip
            notificationPreferenceUpdatedText
            notificationPreferenceLabel
            notificationPreferenceNoneOption
            notificationPreferenceSmsOption
            notificationPreferenceEmailOption
            }
        }
    }
`;

export const GET_HEALTH_AND_SUPPORT_PAGE_CONTENT_QUERY = `
query GetmmcHealthAndRecoverySupportPageCollectionForFund($fundName: [String]) {
    items: mmcHealthAndRecoverySupportCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
        items {
            pageHeader
            pageSubtext
            supportLovedOnesHeader
            carerAssistSubtext
            griefSupportSubtext
            doDSubtext,
            carerAssistLinkText
            carerAssistLink
            griefSupportLinkText
            griefSupportLink
            doDLinkText
            doDLink
            carerAssistImage
            {
                title
                description
                url
            }
            griefSupportImage
            {
                title
                description
                url
            }
            doDImage
            {
                title
                description
                url
            }
            supportInRecoveryHeader
            supportServicesSubtext
            supportServicesImage
            {
                title
                description
                url
            }
            supportServicesLink
            supportServicesLinkText
            supportGuidesSubtext
            supportGuidesImage
            {
                title
                description
                url
            }
            supportGuidesLink
            supportGuidesLinkText
            healthConnectorText
            healthConnectorLink
            healthConnectorLinkText
            healthConnectorCampaignImage
            {
                title
                description
                url
            }
            supportServiceItems
            }
            
        }
    }
`;

export const GET_NO_CLAIMDETAILS_CONTENT_QUERY = `
    query GetmmcNoClaimContentCollectionForFund($fundName: [String]) { 
        items: mmcNoClaimContentCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
            items {
                noClaimHeading                
                noActiveClaimHeading                
                noClaimDescription{json}
                noActiveClaimDescription{json}
                }
            }
        }
`;

export const GET_NEED_HELP_PAGE_CONTENT_QUERY = `
query GetmmcNeedHelpPageCollectionForFund($fundName: [String]) { 
    items: mmcNeedHelpCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
        items {
            contentfulPageName
            pageHeader
            pageSubtext
            questionHeader
            questionDescription{json}
            faqs
            }
        }
    }
`;

export const GET_YOUR_DECLARATION_PAGE_CONTENT_QUERY = `
query GetmmcYourDeclarationPageCollectionForFund($fundName: [String]) { 
    items: mmcYourDeclarationCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
        items {
            contentfulPageName
            pageHeader
            declarationStatement
            declarationStatement2
            buttonText
            }
        }
    }
`;

export const GET_STATUS_DESCRIPTION_PAGE_CONTENT_QUERY = `
query GetmmcStatusDescriptionPageCollectionForFund($fundName: [String]) { 
    items: mmcStatusDescriptionPageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
        items {
            contentfulPageName
            pageTitle
            buttonText
            claimStatuses
            }
        }
    }
`;

export const GET_FOOTER_PAGE_CONTENT_QUERY = `
query GetmmcFooterPageCollectionForFund($fundName: [String]) { 
    items: mmcFooterCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
        items {
            contentfulPageName
            footerText
            privacyLinkText
            privacyLinkValue
            disclaimerLinkText
            disclaimerLinkValue
            fundPrivacyLinkText
            fundPrivacyLinkValue
            }
        }
    }
`;

export const GET_FUND_BANNER_DETAILS_CONTENT_QUERY = `
query GetmmcFundBannerCollectionForFund($fundName: [String]) { 
    items: mmcFundBannerCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
        items {
            headerText
            subHeaderText
            brandLogo{url}
            }
        }
    }
`;

export const GET_MESSAGEHUB_DETAILS_CONTENT_QUERY = `
query GetMmcMessageHubPageCollectionForFund($fundName: [String]) { 
    items: mmcMessageHubPageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
        items {
                pageHeader
                pageSubtext
                correspondenceHeader
                correspondenceSubText
                filterText
                noCorrespondenceText
                noResult
            }
        }
    }
`;

export const GET_ERROR_PAGES_CONTENT_QUERY = `
    query GetmmcErrorPagesCollectionForFund($fundName: [String]) { 
        items: mmcErrorPagesCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
            items {
                unAuthorizedMessage
                sessionTerminatedMessage
                forbiddenMessage
                unHandledErrorMessage
                sessionTimeOutWarning
                stayLoggedInButtonText
                logOutButtonText
                loginLinkText
                timeOutModalHeader
                sessionTimeOutLogOutText
                expiredActivationErrorMessage
                expiredResetPasswordErrorMessage
                }
            }
        }
`;