import React, { memo, useContext, useEffect, useState } from 'react';
import { getGelTokens } from "@tal-gel/theming";
import {
    GelRowLayout,
    GelLabel,
    GelParagraph,
    GelButton,
    GelModal,
    GelContainerLite,
    GelCollapsible,
    GelBoxLayout,
    GelIcon
} from '@tal-gel/components';
import Uploader from '../documentsupload/uploader';
import FileTypes from '../documentsupload/fileTypes';
import { UserContext } from '../../../common/usercontext/user.context';
import { AdobeClickType, NotificationItemIds } from '../../../constants/constants';
import { useLocation } from 'react-router-dom';
import { CLAIM_ASSESSMENT_STATUS } from '../../../constants/constants';
import { AdobeAnalytics } from '../../../common/analytics/adobe-analytics';
import { useCookies } from 'react-cookie';
import { useGetClaimDetailsContent } from '../../../common/api/graphQLDataFetch';

interface OtherDocumentsProps {
    claimId: number;
    contentData: any;
    claimAssesmentStatus: string;
}

const OtherDocuments = (props: OtherDocumentsProps) => {
    const { global: { sizeBaseUnit, themeColorBorderDefault, themeColorBackgroundSuccess, fontFamilySans, fontWeightRegular } } = getGelTokens();
    const location = useLocation();
    const { contextData } = useContext(UserContext);
    const [{ tpid }] = useCookies(["tpid"])

      const { data } = useGetClaimDetailsContent()
    

    const [openModal, setOpenModal] = useState(false);
    const [showRequirementDetails, setShowRequirementDetails] = useState(false);

    const onHeaderClick = () => {
        if (allowSectionCollapse)
            setShowRequirementDetails(!showRequirementDetails);
    }

    let allowSectionCollapse = true;
    const onDisableCollapse = (allowCollapse: boolean) => {
        allowSectionCollapse = allowCollapse;
    }

    useEffect(() => {
        if (location.state?.selectedNotification == NotificationItemIds.OtherDocuments) {
            setShowRequirementDetails(!showRequirementDetails);
        }

    }, []);

    const handleViewSupportedTypesButtonClick = () => {
        AdobeAnalytics.PushClickEvent(tpid, data?.viewSupportedTypes, AdobeClickType.Tooltip)

        setOpenModal(true)
    }

    return (
        <div id={NotificationItemIds.OtherDocuments}>
            <GelRowLayout style={{ padding: sizeBaseUnit * 4 }}>
                <GelContainerLite style={{
                    borderTop: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                    borderLeft: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                    borderRight: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                    paddingTop: sizeBaseUnit,
                    paddingBottom: showRequirementDetails ? sizeBaseUnit * 2 : 0,
                    width: "auto",
                    cursor: "pointer",
                }}
                    onClick={onHeaderClick}>
                    <GelBoxLayout space={[7, 0.1]} style={{
                        borderBottom: showRequirementDetails ? `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}` : "none",
                        width: "auto",
                        padding: sizeBaseUnit * 3,
                        marginLeft: -sizeBaseUnit * 4,
                        marginRight: -sizeBaseUnit * 4
                    }}>
                        <GelLabel
                            style={{
                                fontFamily: fontFamilySans,
                                fontSize: sizeBaseUnit * 4,
                                fontWeight: fontWeightRegular
                            }}>
                            {data?.otherDocumentsAccordionTitle}
                        </GelLabel>

                        <GelIcon
                            name={showRequirementDetails ? "ChevronUp" : "ChevronDown"}
                            width={sizeBaseUnit * 6}
                            color={themeColorBackgroundSuccess}>
                        </GelIcon>
                    </GelBoxLayout>
                </GelContainerLite>

                <GelContainerLite style={{
                    borderBottom: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                    borderLeft: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                    borderRight: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                    paddingBottom: sizeBaseUnit * 2,
                    marginTop: -sizeBaseUnit * 8,
                }} >
                    <GelCollapsible open={showRequirementDetails}>
                        <GelContainerLite style={{ paddingBottom: sizeBaseUnit * 2 }}>
                            <GelRowLayout gutter="xsmall" style={{
                                paddingTop: sizeBaseUnit,
                                paddingBottom: sizeBaseUnit,
                            }}>
                                <GelLabel>
                                    {props.claimAssesmentStatus === CLAIM_ASSESSMENT_STATUS.RN ? data?.documentClaimNotifiedText
                                        : data?.otherDocumentText}
                                </GelLabel>

                                <GelParagraph style={{ paddingRight: sizeBaseUnit * 4 }}>
                                    {data?.maxFileSize}
                                </GelParagraph>

                                <GelButton tertiary small onClick={handleViewSupportedTypesButtonClick}>
                                    {data?.viewSupportedTypes}
                                </GelButton>

                                <GelModal
                                    width="sm"
                                    height={sizeBaseUnit * 80}
                                    open={openModal}
                                    onClose={() => setOpenModal(false)}
                                    sticky
                                    title={data?.viewSupportedTypes}
                                >
                                    <FileTypes />
                                </GelModal>
                            </GelRowLayout>

                            <Uploader
                                contentFullData={props.contentData}
                                requirement={{
                                    claimId: props.claimId,
                                    associatedDocuments: contextData?.otherDocuments,
                                }}
                                setAllowCollapse={onDisableCollapse}
                            />
                        </GelContainerLite>
                    </GelCollapsible>
                </GelContainerLite>
            </GelRowLayout>
        </div>
    );
};

export default memo(OtherDocuments);