import React, { useContext } from 'react';
import {
    GelRowLayout,
    GelLabel
} from '@tal-gel/components';
import NotificationItem from './notification-item';
import { getGelTokens } from "@tal-gel/theming";
import { UserContext } from "../../../common/usercontext/user.context";

const NotificationList = ({ requirements }) => {
    const { global: { sizeBaseUnit } } = getGelTokens()
    const { contextData, setUserContext } = useContext(UserContext);

    return (
        <div style={{ paddingTop: sizeBaseUnit * 2, paddingBottom: sizeBaseUnit }}>
            {requirements && requirements.length > 0 ? (
                <GelRowLayout>
                    {requirements.map((req) => (
                        <NotificationItem
                            key={req.id}
                            requirement={req}
                            memberNumber={contextData.memberDetails?.memberNumber!}
                            superfundId={contextData.memberDetails?.policyManagerId!}
                        />
                    ))}
                </GelRowLayout>
            ) : (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    color: '#666',
                    padding: `${sizeBaseUnit}px 0`,
                    height: `50px`
                }}>
                    <GelLabel>You have no tasks to complete.</GelLabel>
                </div>
            )}
        </div>
    );
};

export default NotificationList;