import React, { useContext, useEffect, useState } from "react";
import {
    GelForm,
    GelFormField,
    GelPasswordInput,
    useGelFormData,
    GelButton,
    GelBoxLayout,
    GelScreenDetectorContext,
    GelContainerLite,
    GelSelect,
    GelIcon,
    GelLabel,
    GelLink,
    GelEmailInput,
    GelRowLayout,
    GelHeading3,
    GelParagraph,
    GelSpinner,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { AuthnTransactionData, TRANSACTION_STATUS, getOktaUsername } from "../../common/authentication/auth.utils";
import { useNavigate, useParams } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { AccountApi } from "../../common/api/auth.provider";
import { UserContext } from "../../common/usercontext/user.context";
import { ThemeContext } from "../.././common/usercontext/themes.context";
import FundBanner from "../../components/fundBanner";
import { useCookies } from "react-cookie";
import { AdobeClickType } from "../../constants/constants";
import { AdobeAnalytics } from "../../common/analytics/adobe-analytics";
import { useGetLoginPageContentData, useGetSuperfundData } from "../../common/api/graphQLDataFetch";

const LoginPage: React.FC = () => {
    const {
        global: { sizeBaseUnit, sizeNone, themeColorTextDanger },
    } = getGelTokens();

    const navigate = useNavigate();
    const { oktaAuth, authState } = useOktaAuth();
    const { screen, isXsScreen, isLargerThanSmScreen } = useContext(
        GelScreenDetectorContext
    ) as GelScreenDetectorContext;
    const { setUserContext } = useContext(UserContext);
    const { setThemeContext } = useContext(ThemeContext);
    const [loginStatusMessage, setloginStatusMessage] = useState<string>("");
    const [superfundId, setSuperFund] = useState<string>("");
    const [isSuperFundFromUrl, setIsSuperFundFromUrl] = useState<boolean>(false);
    const [_, setssoCookies, removeCookie] = useCookies(["aid", "rid", "ssoid", "tpid"]);

    const superFundData = useGetSuperfundData();
    const loginPageData = useGetLoginPageContentData();

    const { fund } = useParams();

    const { formData, onFormDataChange, onFormDataReset, resetForm } = useGelFormData({
        email: "",
        password: "",
        superfundId: "",
    });

    const onSuperFundChange = (event) => {
        setSuperFund(event.target.value);
    };

    useEffect(() => {
        if (authState && authState.isAuthenticated) {
            navigate("/claims");
            return;
        }

        if (!fund) {
            removeCookie("tpid");
            AdobeAnalytics.PushPageViewEvent("");
            return;
        }

        if (fund && !superFundData.isLoading) {
            const superFundNameForAdobeAnalytics = getSuperFundNameForAdobeAnalytics();

            const selectedSuperFund = superFundData.data?.superFundList?.find(
                (superFund: { displayName: string }) => superFund.displayName === fund
            );

            if (!selectedSuperFund) {
                navigate("/invalidfund");
                return;
            }

            const {
                superfundId: selectedSuperFundId,
                superFundPrefix,
                theme: selectedSuperFundTheme,
            } = selectedSuperFund;

            sessionStorage.setItem(
                superFundNameForAdobeAnalytics?.superFundPrefix.toLowerCase(),
                superFundNameForAdobeAnalytics?.adobeAnalyticsSuperfundMapping.toLowerCase()
            );

            setssoCookies("tpid", superFundPrefix.toLowerCase());

            setThemeContext({
                theme: selectedSuperFundTheme,
                fund: fund,
            });

            setIsSuperFundFromUrl(true);
            setSuperFund(selectedSuperFundId);

            AdobeAnalytics.PushPageViewEvent(superFundPrefix.toLowerCase());
        }
    }, [fund, superFundData.isLoading]);

    const getSuperFundNameForAdobeAnalytics = (): Record<string, any> => {
        const superFundList = superFundData.data?.superFundList;

        if (fund) {
            return superFundList?.find((superFund: { displayName: string }) => superFund.displayName === fund);
        }

        return superFundList?.find((item: { superfundId: string }) => item.superfundId.toString() === superfundId);
    };

    const onSubmit = () => {
        const superFundNameForAdobeAnalytics = getSuperFundNameForAdobeAnalytics();

        AdobeAnalytics.PushClickEvent(
            superFundNameForAdobeAnalytics.adobeAnalyticsSuperfundMapping,
            loginPageData.data?.loginButtonText,
            AdobeClickType.Button
        );

        var umbrellaFunds =
            superFundData.data?.superFundList?.find((x) => x.superfundId == superfundId)?.umbrellaFundIds?.split(",") ??
            [];
        umbrellaFunds.push(superfundId);
        oktaLogin(umbrellaFunds);

        sessionStorage.setItem(
            superFundNameForAdobeAnalytics?.superFundPrefix.toLowerCase(),
            superFundNameForAdobeAnalytics?.adobeAnalyticsSuperfundMapping.toLowerCase()
        );
        setssoCookies("tpid", superFundNameForAdobeAnalytics?.superFundPrefix.toLowerCase());
    };

    const oktaLogin = (superFundIds: string[]) => {
        var superfundId = superFundIds.pop();

        const oktaUsername = getOktaUsername(superfundId!, formData.email);
        oktaAuth.revokeAccessToken().then(() => {
            oktaAuth
                .signIn({
                    username: oktaUsername,
                    password: formData.password,
                })
                .then((res: AuthnTransactionData) => {
                    if (res.status === TRANSACTION_STATUS.MFA_REQUIRED) {
                        setloginStatusMessage("Valid credentials, MFA Required");
                        const factor = res.factors?.find((f) => f.factorType === "sms");
                        if (!factor) {
                            console.log(`User not enrolled in sms factor`);
                        }
                        const phoneNumber = factor?.profile.phoneNumber.replaceAll(" ", "").replaceAll("X", "*");
                        navigate("/verifycode");
                        setUserContext({
                            memberDetails: {
                                stateToken: res.data?.stateToken!,
                                mobileNumber: phoneNumber,
                                factorId: factor?.id,
                                policyManagerId: Number(superfundId),
                            },
                            claims: [],
                        });
                        AccountApi.sendMfaCode(factor?.id, res.data?.stateToken!)
                            .then((response) => response.json())
                            .then((data) => {
                                console.log("sms sent", data);
                            })
                            .catch((httpError) => {
                                console.log("failed to send MFA sms", httpError);
                                setloginStatusMessage("Failed sending sms. Please try again");
                            });
                    } else if (res.status === TRANSACTION_STATUS.SUCCESS) {
                        console.log("MFA not required for member, authentication succcessful");
                        // Store automation test account password in session storage for redirect to EFT/TFN microsites
                        //Storage.setItem(SESSION_STORAGE_KEY.ACCOUNT_TYPE, ACCOUNT_TYPE.AUTOMATION_ACCOUNT);
                        // Retreive ID & Access token from Okta

                        oktaAuth.signInWithRedirect({ sessionToken: res.data?.sessionToken });
                    } else if (res.status === TRANSACTION_STATUS.LOCKED_OUT) {
                        setloginStatusMessage(
                            "Your account has been locked. Please reach out to your Claims Consultant"
                        );
                    }
                })
                .catch((httpError) => {
                    console.log(superFundIds, "super funds ids");
                    if (superFundIds?.length > 0) {
                        console.log("check for umbrellafunds");
                        oktaLogin(superFundIds);
                    } else {
                        console.log("failed to login", httpError);
                        setloginStatusMessage(loginPageData.data?.errorMessageContent.invalidLoginMsg);
                    }
                });
        });
    };

    const navigateToSignUp = () => {
        AdobeAnalytics.PushClickEvent(
            getSuperFundNameForAdobeAnalytics(),
            loginPageData.data?.signUpLink,
            AdobeClickType.Link
        );

        navigate("/signup");
    };

    const navigateToPasswordReset = () => {
        AdobeAnalytics.PushClickEvent(
            getSuperFundNameForAdobeAnalytics(),
            loginPageData.data?.forgotPasswordLink,
            AdobeClickType.Link
        );

        navigate("/forgotPassword");
    };

    if (loginPageData.isLoading || superFundData.isLoading) return <GelSpinner medium overlay />;

    return (
        <GelContainerLite
            style={{
                paddingRight: sizeNone,
                paddingLeft: sizeNone,
                paddingBottom: sizeBaseUnit * 15,
                paddingTop: sizeBaseUnit * 15,
                overflow: "hidden",
            }}
        >
            <GelBoxLayout alignment="start" space={[1, 4]} gutter="none">
                <GelForm
                    labelAtTop={true}
                    width={isXsScreen(screen) ? "100%" : sizeBaseUnit * 110}
                    {...(!isXsScreen(screen) && {
                        labelWidth: sizeBaseUnit * 50,
                    })}
                    fieldGutter={sizeBaseUnit * 10}
                    disableOnSubmit
                    onSubmit={onSubmit}
                    reset={resetForm}
                    onReset={onFormDataReset}
                    parseResponseBody={false}
                    style={{
                        paddingRight: sizeBaseUnit * 16,
                        paddingLeft: sizeBaseUnit * 16,
                    }}
                >
                    <GelRowLayout gutter="medium" style={{ paddingLeft: sizeBaseUnit * 3 }}>
                        <GelHeading3>{loginPageData.data?.headerText}</GelHeading3>
                        <GelParagraph style={{ paddingTop: sizeBaseUnit * 2 }}>
                            {loginPageData.data?.subText}
                        </GelParagraph>

                        {!isSuperFundFromUrl && (
                            <GelFormField label={loginPageData.data?.superFundText}>
                                <GelSelect
                                    name="superfund"
                                    onChange={onSuperFundChange}
                                    placeholder="Please select"
                                    options={superFundData.data?.superFundList
                                        ?.filter((superFund) => superFund.hideInDropDown == false)
                                        ?.map((superFund) => ({
                                            label: superFund.superfundName,
                                            value: superFund.superfundId,
                                        }))}
                                    required
                                    requiredErrorMsg={loginPageData.data?.errorMessageContent.superFundReqMsg}
                                />
                            </GelFormField>
                        )}

                        <GelFormField label={loginPageData.data?.emailText}>
                            <GelEmailInput
                                name="email"
                                onChange={onFormDataChange}
                                required
                                value={formData.email}
                                requiredErrorMsg={loginPageData.data?.errorMessageContent?.emailReqMsg}
                                formatErrorMsg={loginPageData.data?.errorMessageContent?.emailFormatMsg}
                            />
                        </GelFormField>

                        <GelFormField label={loginPageData.data?.passwordText}>
                            <GelPasswordInput
                                name="password"
                                value={formData.password}
                                onChange={onFormDataChange}
                                required
                                requiredErrorMsg={loginPageData.data?.errorMessageContent.passwordReqMsg}
                            />
                        </GelFormField>

                        {loginStatusMessage && (
                            <GelBoxLayout space={[1, 8]}>
                                <GelIcon color={themeColorTextDanger} name="AlertCircle" inline />
                                <GelLabel style={{ color: themeColorTextDanger }}>{loginStatusMessage}</GelLabel>
                            </GelBoxLayout>
                        )}
                        <GelFormField style={{ paddingTop: sizeBaseUnit * 4 }}>
                            <GelButton
                                name=""
                                style={{
                                    width: "100%",
                                }}
                                primary
                                large
                                submit
                            >
                                {loginPageData.data?.loginButtonText}
                            </GelButton>
                        </GelFormField>

                        <div style={{ paddingTop: sizeBaseUnit * 4 }}>
                            <GelLabel
                                style={{
                                    fontWeight: 100,
                                }}
                            >
                                {loginPageData.data?.forgotPasswordText}
                                <GelLink onClick={navigateToPasswordReset}>
                                    {" "}
                                    {loginPageData.data?.forgotPasswordLink}
                                </GelLink>
                            </GelLabel>
                        </div>

                        <div style={{ paddingBottom: sizeBaseUnit * 4 }}>
                            <GelLabel
                                style={{
                                    fontWeight: 100,
                                }}
                            >
                                {loginPageData.data?.signUpText}
                                <GelLink onClick={navigateToSignUp}> {loginPageData.data?.signUpLink}</GelLink>
                            </GelLabel>
                        </div>
                    </GelRowLayout>
                </GelForm>
                {isLargerThanSmScreen(screen) && <FundBanner />}
            </GelBoxLayout>
        </GelContainerLite>
    );
};
export default LoginPage;
