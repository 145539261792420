export const GET_SUPERFUND_DATA_QUERY = `
  query superFundList {
        superFundList {
            superfundName
            superfundId
            isDisplayed
            displayName
            theme
            hideInDropDown
            umbrellaFundIds
            superFundPrefix
            adobeAnalyticsSuperfundMapping
        }# getSuperFundList
    }`;


export const SCAN_DOCUMENT_QUERY =
    `mutation UploadDocumentConfirmation($fileScanId: Int!, $claimId: Int!) {
        uploadDocumentConfirmation(fileScanId: $fileScanId, claimId: $claimId) {
            id
            scanStatus
            displayName
            documentIdentifier

        }
    }`;

export const GET_MEMBER_DATA_QUERY = `
query memberRecord {
    memberRecord {
        memberId
        memberNumber
        displayLegalDisclosure
        policyManagerId
        unReadDigiCorroMessageCount
        notificationPreference
        claims {
            claimId
            claimNo
            caseNumber
            claimTypeName
            caseManager
            claimAssesmentStatus
            activities{
                claimPaymentId
                description
                title
                date
                type
                friendlyText
            }# activities
            requirements {
                id
                claimId
                description
                friendlyText
                requestedDate
                receivedDate
                completedDate
                associatedDocuments{
                    documentName
                    displayName
                    sharePointDocumentIdentifier
                }#associatedDocuments
            }# requirements
            details {
                sumInsured
                policyOwner
                claimSCBenefitPeriod
                claimSCWaitingPeriod
                masterPolicyNo
                monthlyBenefit
                lifeInsured
            }# details
            status{
                friendlyStatus  
                description                  
                thirdPartyRequirementInfoText
                claimProgressStatusListForFund
            }# status
            nextFuturePayment{
                isSuccess
                value{
                    dispatchDate
                    createdDate
                    netAmount
                }#value
            }#nextFuturePayment
        }# claims
        documentsHistory{
            isSuccess
            value{
            claimId
            documentName
            displayName
            isAssociatedRequirementFound
            sharePointDocumentIdentifier
            }#value
        }#documentsHistory
        profile{
            fullName
            dateOfBirth
            email
            contactNumber
            address
        }#profile
      }# getMemberRecord
  }`;

export const GET_MEMBER_NOTIFICATIONS_QUERY = `
query newNotifications {
    newNotifications{
        unreadCount
        list{
            notificationId
            claimId
            sourceId
            type
            title
            text
            createdOn
            readOn
        }#list
    }
}`;

export const GET_MEMBER_DOCUMENT_QUERY =`query downloadDocument($documentIdentifier: String!, $fileName: String!) {
    downloadDocument(documentIdentifier: $documentIdentifier, fileName: $fileName) {
        content
}}`;

export const GET_REFRESH_SESSION_QUERY = `
query refreshUserSession {
    refreshUserSession {
       success 
      }
  }`;

export const GET_LIST_OF_DIGICORRESPONDENCE_QUERY = `
query listDigiCorrespondences {
    listDigiCorrespondences{
        digiCorroId
        fineosClaimCaseNumber
        claimType
        documentTitle
        description
        classification
        createdOn
        isRead
    }
  }`;

export const GET_MEMBER_DIGICORRESPONDENCE_DOCUMENT_QUERY = `
query downloadDigiCorrospondenceDocument($digiCorroId: Int!) {
    downloadDigiCorrospondenceDocument(digiCorroId: $digiCorroId) {
        content
        fileName
    }
}`;
