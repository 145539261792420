import React, { useContext, useEffect, useState } from "react";
import { getGelTokens } from "@tal-gel/theming";
import {
  GelScreenDetectorContext,
  GelRowLayout,
  GelContainerLite,
  GelTabs,
  GelTab,
  GelHeading2,
  GelParagraph,
  GelSpinner
} from "@tal-gel/components";
import { UserContext } from "../../common/usercontext/user.context";
import { useLocation, useNavigate } from "react-router-dom";
import ClaimBenefit from "./policy/claimBenefit";
import {
  ACTIVITY_TYPE,
  API,
  CLAIM_ASSESSMENT_STATUS,
  FuturePaymentNotificationId,
  NotificationType,
} from "../../constants/constants";
import Card from "../../common/components/card";
import styled from "@emotion/styled";
import axios from "axios";
import { GET_MEMBER_NOTIFICATIONS_QUERY } from "../../graphql/queries/graphql-queries.constant";
import { FormatHelper } from "../../common/formatHelper.common";
import ClaimHistory from "./history/claimHistory";
import { useCookies } from "react-cookie";
import OtherDocuments from "./otherDocuments/otherdocuments";
import ResponsiveContainer from "../../components/responsive.container";
import SESSION_STORAGE_KEY from '../../constants/storage.constant';
import { AdobeAnalytics } from "../../common/analytics/adobe-analytics";
import { useGetClaimDetailsContent } from "../../common/api/graphQLDataFetch";
import NotificationCard from "./notifications/notification-card";
import { MemberNotification } from "../../common/models/memberclaims";


const { global: { sizeBaseHalf, sizeNone, sizeBaseX20, sizeBaseX3, sizeBaseX15, sizeBaseX5, sizeBaseX10, themeColorBackgroundLight, sizeBaseUnit } } = getGelTokens();

const TabBorderOverride = styled.div(() => ({
  margin: -sizeBaseUnit / 4,
  border: sizeNone
}));

const ClaimDetailsContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading } = useGetClaimDetailsContent()
  const [{ aid, rid, ssoid, tpid }] = useCookies(["aid", "rid", "ssoid", "tpid"]);

  const { screen, isXsScreen } = useContext(
    GelScreenDetectorContext
  ) as GelScreenDetectorContext;

  const { contextData, setUserContext } = useContext(UserContext);
  const [notificationDataLoaded, setnotificationDataLoaded] = useState(false);
  const [claimCookie] = useCookies(["selectedClaim"]);
  const [ notifications, setNotifications ] = useState<MemberNotification[]>([]);


  const PageHeader = styled.div(() => ({
    padding: isXsScreen(screen)
      ? `${sizeBaseX15}px ${sizeBaseX5
      }px`
      : `${sizeBaseX15}px ${sizeBaseX10
      }px`,
    backgroundColor: themeColorBackgroundLight,
  }));

  if (contextData.memberDetails?.displayLegalDisclosure)
    navigate("/yourDeclaration");

  useEffect(() => {
    AdobeAnalytics.PushFormStepEvent(tpid)
    AdobeAnalytics.PushPageViewEvent(tpid)

    // [CAMMC-665] Ensure we only fetch notifications once member request is complete, Probably a better way to do this
    if (contextData?.memberDetails?.memberNumber) {
      loadNotifications();
    }
  }, [contextData?.memberDetails?.memberNumber]);

  // TODO: refactor this function
  const loadNotifications = () => {
    axios
      .post(
        API.BASEURL,
        {
          query: GET_MEMBER_NOTIFICATIONS_QUERY,
        },
        {
          headers: {
            'x-aid': aid,
            'x-rid': rid,
            ssoid,
            tpid,
            'sid': window.sessionStorage.getItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN)
          }
        })
      .then(async (response) => {
        let notificationsResponse = response.data?.data?.newNotifications?.list || [] as MemberNotification[];
        setNotifications(notificationsResponse);

        addNotificationsToContext(notificationsResponse.filter(n =>
          (n.type == NotificationType.ProceesedPayment || n.type == NotificationType.Activity)));
        setnotificationDataLoaded(true);

        if (location.state?.selectedNotification) {
          setTimeout(() => {
            const element = document.getElementById(
              location.state?.selectedNotification
            );
            if (element) element.scrollIntoView({ behavior: "smooth" });
          }, 300);
        }
      });
  };

  const addNotificationsToContext = (notifications: MemberNotification[]) => {
    removeProcessedNotificationFromContext();
    notifications?.forEach((nt) => {
      let claim = contextData.claims?.find(
        (claim) => claim.claimId == nt.claimId
      );

      if (
        claim &&
        claim.requirements.filter(
          (rq) =>
            rq.id == nt.notificationId
        ).length === 0
      ) {

        claim?.requirements.push({
          description: nt.text,
          friendlyText: nt.title!,
          type: nt.type == 1 ? NotificationType.ProceesedPayment : NotificationType.Activity,
          id: nt.notificationId,
          claimId: nt.claimId,
          requestedDate: nt.createdOn,
          completedDate: nt.readOn!,
        });
      }
    });

    contextData.claims?.forEach((claim) => {
      if (claim.nextFuturePayment.isSuccess) {
        if (
          claim &&
          claim.requirements.filter(
            (rq) =>
              rq.id == FuturePaymentNotificationId &&
              rq.type == NotificationType.FuturePayment
          ).length == 0
        ) {
          claim.requirements.push({
            description: claim.nextFuturePayment.value.dispatchDate,
            friendlyText: claim.nextFuturePayment.value.netAmount.toString(),
            type: NotificationType.FuturePayment,
            id: FuturePaymentNotificationId,
            claimId: claim.claimId,
            requestedDate: claim.nextFuturePayment.value.createdDate,
          });
        }
      }
    });

    contextData.claims?.map((claim) => {
      claim.activities.map((ac) => (ac.hideActivity = false));
    });

    notifications?.forEach((nt) => {
      let claim = contextData.claims?.find(
        (claim) => claim.claimId == nt.claimId
      );
      if (claim) {

        claim.activities
          .filter((at) => at.claimPaymentId == nt.sourceId)
          .map((x) => (x.hideActivity = true));

        if (nt.type == NotificationType.Activity) {
          claim.activities
            .filter((at) => at.type == ACTIVITY_TYPE.ACTIVITY)
            .map((x) => (x.hideActivity = true));
        }
      }
    });

    contextData.claims?.forEach((claim) => {
      claim.requirements.sort(
        (a, b) =>
          FormatHelper.toDate(b.requestedDate).getTime() -
          FormatHelper.toDate(a.requestedDate).getTime()
      );
    });
    setUserContext(contextData);
  };

  const removeProcessedNotificationFromContext = () => {
    contextData.claims?.forEach((claim) => {
      claim.requirements
        .filter((rq) => rq.type == NotificationType.ProceesedPayment || rq.type == NotificationType.Activity)
        .forEach((ppay) => {
          claim.requirements.splice(claim.requirements.indexOf(ppay), 1);
        });
    });
  };

  var selectedClaimType = claimCookie.selectedClaim;

  if (selectedClaimType) {
    const selectedclaimDetails = contextData.claims?.find(
      (c) => c.claimTypeName === selectedClaimType
    );
    var index = selectedclaimDetails
      ? contextData.claims.indexOf(selectedclaimDetails)
      : -1;
    let sortedClaimDetails = [...contextData.claims];
    if (selectedclaimDetails) {
      sortedClaimDetails.splice(index, 1);
      sortedClaimDetails.unshift(selectedclaimDetails);
      contextData.claims = sortedClaimDetails;
    }
  }

  if (isLoading) return <GelSpinner medium overlay />;

  return (
    <GelContainerLite
      style={{ padding: sizeNone, overflow: "hidden" }}
    >
      <PageHeader>
        <GelHeading2>
          {data?.headerText}
        </GelHeading2>

        <GelParagraph style={{ paddingTop: sizeBaseX3 }}>
          {data?.headerTextDescription}
        </GelParagraph>
      </PageHeader>


      <GelTabs style={{ border: sizeNone, marginRight: -sizeBaseHalf }}>
        {contextData.claims?.map((claim, index) => (
          <GelTab
            key={Math.random()}
            title={claim.claimTypeName}
            tabindex={index}
            removeContentPadding
          >
            <TabBorderOverride>
              <ClaimBenefit
                claimBenefit={claim}
                contentData={data}
              />

              <ResponsiveContainer>
                <GelRowLayout
                  style={{
                    padding: isXsScreen(screen)
                      ? `${sizeBaseX10}px ${sizeBaseX5
                      }px`
                      : `${sizeBaseX5}px ${sizeBaseX10
                      }px`,
                  }}
                >
                  {claim?.claimAssesmentStatus !== CLAIM_ASSESSMENT_STATUS.RN && (
                    <NotificationCard 
                      requirements={claim.requirements}
                      isNotificationDataLoaded={notificationDataLoaded}
                      claimDetailsContentfulModel={data}
                      notifications={notifications}
                    />
                  )}

                  <Card
                    key={crypto.randomUUID()}
                    title={data?.otherDocumentsCardHeader}
                    lightHeader={true}
                    subTitle={data?.otherDocumentsCardHeaderDescription}
                    showFooter={false}
                  >
                    {notificationDataLoaded && (
                      <OtherDocuments
                        contentData={data}
                        claimId={claim.claimId}
                        claimAssesmentStatus={claim.claimAssesmentStatus}
                      />
                    )}
                  </Card>

                  <Card
                    key={crypto.randomUUID()}
                    title={data?.claimsHistoryCardHeader}
                    lightHeader={true}
                    subTitle={data?.claimsHistoryCardHeaderDescription}
                    showFooter={false}
                  >
                    {!notificationDataLoaded && (
                      <div style={{ height: sizeBaseX20 }}>
                        <GelSpinner medium overlay />
                      </div>
                    )}

                    {notificationDataLoaded && (
                      <ClaimHistory
                        claimDetails={claim}
                      />
                    )}
                  </Card>

                </GelRowLayout>
              </ResponsiveContainer>
            </TabBorderOverride>
          </GelTab>
        ))}
      </GelTabs>

    </GelContainerLite>
  );
};

export default ClaimDetailsContainer;
