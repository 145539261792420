import React, { useContext, useEffect, useState } from 'react';
import {
    GelForm, GelFormField,
    GelPasswordInput,
    useGelFormData,
    GelButton,
    GelBoxLayout,
    GelScreenDetectorContext,
    GelContainerLite,
    GelIcon,
    GelLabel,
    GelHeading3,
    GelParagraph,
    GelRowLayout,
    GelList,
    GelListItem,
    GelSpinner
} from '@tal-gel/components';

import { getGelTokens } from '@tal-gel/theming';
import { TRANSACTION_STATUS } from '../../common/authentication/auth.utils';
import { useNavigate, useParams } from "react-router-dom";
import { AccountApi } from '../../common/api/auth.provider';
import { UserContext } from '../../common/usercontext/user.context';
import FundBanner from "../../components/fundBanner";
import { useGetResetPasswordContentData } from '../../common/api/graphQLDataFetch';
import { useCookies } from 'react-cookie';
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';
import { OktaErrors } from '../../common/models/enums/oktaErrors.model';
import { ErrorPageStatus } from '../../common/models/enums/errorPageStatus.model';

const ResetPassword: React.FC = () => {
    const { global: { sizeNone, sizeBaseUnit, themeColorBackgroundDanger, sizeBaseX3, themeColorTextDanger } } = getGelTokens()
    const { data, isFetched } = useGetResetPasswordContentData()

    const navigate = useNavigate();
    const [recoveryTokenVerified, setrecoveryTokenVerified] = useState(false);
    const { screen, isXsScreen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const [loginStatusMessage, setloginStatusMessage] = useState("");
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const { setUserContext } = useContext(UserContext);
    const [{ tpid }] = useCookies(["tpid"])


    let { recoverytoken } = useParams();
    let restPasswordStateToken = "";
    let userId = "";

    const {
        formData,
        onFormDataChange,
        onFormDataReset,
        resetForm
    } = useGelFormData({
        email: ''
    });

    const { contextData } = useContext(UserContext)

    useEffect(() => {
        const fundName = tpid ?? ""
        AdobeAnalytics.PushPageViewEvent(fundName)

        if (recoverytoken) {
            AccountApi.verifyRecoveryToken(recoverytoken)
                .then(response => response.json())
                .then(tokenData => {
                    console.log('verifyRecoveryToken data', tokenData);
                    if (!tokenData.errorCode) {
                        restPasswordStateToken = tokenData.stateToken
                        userId = tokenData._embedded.user.profile.login;
                        setUserContext({ memberDetails: { factorId: '', stateToken: restPasswordStateToken, mobileNumber: '', oktaEmail: userId }, claims: [] });
                        setrecoveryTokenVerified(true);
                    }
                    else if (tokenData.errorCode === OktaErrors.EXPIRED_OR_USED_RESET_PASSWORD_LINK) {
                        navigate("/error", { state: { errorCode: ErrorPageStatus.EXPIRED_OR_USED_RESET_PASSWORD_LINK } })
                    }
                    else {
                        console.log('verifyRecoveryToken data failed');

                    }
                })
                .catch(httpError => {
                    console.log('verifyRecoveryToken Failed. Please try again', httpError);
                    setloginStatusMessage("Failed verifyRecoveryToken. Please try again");
                });

        }
    }, [tpid]);

    const onSubmit = () => {
        AccountApi.resetPassword(formData.password, contextData.memberDetails?.stateToken!)
            .then(response => response.json())
            .then(data => {
                const { status } = data;
                if (status.toString() === TRANSACTION_STATUS.MFA_REQUIRED) {
                    let mfaStateToken = data.stateToken;
                    console.log('Valid credentials, MFA Required');
                    //User has been enrolled in a sms factor, retreive factorId and send MFA
                    const factor = data._embedded.factors.find(f => f.factorType === 'sms');
                    const factorId = factor?.id;
                    if (!factor) {
                        console.error(`User not enrolled in sms factor`);
                    }
                    // Extract members phone number from sms factor
                    const phoneNumber = factor?.profile.phoneNumber.replaceAll(' ', '').replaceAll('X', '*');
                    if (phoneNumber) {
                        setUserContext({ memberDetails: { factorId: factorId, stateToken: mfaStateToken, mobileNumber: phoneNumber, oktaEmail: contextData.memberDetails?.oktaEmail }, claims: [] });
                        navigate("/confirmMobile");
                    }
                    else {
                        navigate("/confirmMember");
                    }
                }
                else {
                    //Unrecognised state, after successfully resetting password user should be prompted for MFA
                    //this.setState({errorMessage: 'EnrollSmsFactorRequestNotAllowed'});
                }
            }).catch(httpError => {
                console.error('reset password failed errorMessage:', httpError);
                setloginStatusMessage("reset password failed");
            });
    };

    const handlePasswordInput = (event) => {
        // Check if passwords match    
        if (formData.confirmPassword) {
            setPasswordsMatch(formData.confirmPassword == event.target.value)
        }
    };

    const handleConfirmPasswordInput = (event) => {
        // Check if passwords match
        setPasswordsMatch(formData.password && formData.password == event.target.value);
    };

    if (!isFetched || !recoveryTokenVerified) {
        return <GelSpinner medium overlay />
    }

    return (
        <GelContainerLite style={{
            paddingRight: sizeNone,
            paddingLeft: sizeNone,
            paddingBottom: sizeBaseUnit * 15,
            paddingTop: sizeBaseUnit * 15, overflow: "hidden"
        }}>
            <GelBoxLayout alignment="start" space={[1, 4]} gutter="none">
                <GelForm
                    labelAtTop={true}
                    width={isXsScreen(screen) ?
                        '100%' : sizeBaseUnit * 110
                    }
                    {...!isXsScreen(screen) && {
                        labelWidth: sizeBaseUnit * 50
                    }}
                    fieldGutter={sizeBaseUnit * 10}
                    disableOnSubmit
                    onSubmit={onSubmit}
                    reset={resetForm}
                    onReset={onFormDataReset}
                    parseResponseBody={false}
                    style={{
                        paddingRight: sizeBaseUnit * 16,
                        paddingLeft: sizeBaseUnit * 16
                    }}>
                    <GelRowLayout gutter="medium" style={{ paddingLeft: sizeBaseUnit * 3 }}>
                        <GelHeading3 style={{ padding: "20px 0px" }}>
                            {data?.headerText}
                        </GelHeading3>
                        <GelParagraph style={{ marginTop: -sizeBaseUnit * 2 }}>{data?.headerSubtext}</GelParagraph>
                        <GelContainerLite style={{ backgroundColor: "#D5F0F0", padding: "16px" }}>
                            <GelLabel >{data?.passwordRequirementsHeader}</GelLabel>
                            <GelParagraph>{data?.passwordRequirementsSubtext}</GelParagraph>
                            <GelList style={{ paddingTop: "10px" }}>
                                {data?.passwordRequirementsList.map((req) => (
                                    <GelListItem key={crypto.randomUUID()}>{req}</GelListItem>
                                ))}
                            </GelList>
                        </GelContainerLite>

                        <GelFormField style={{ padding: "" }}
                            label={data?.newPassword}
                        >
                            <GelPasswordInput
                                name="password"
                                value={formData.password}
                                onChange={onFormDataChange}
                                onInput={handlePasswordInput}
                                required
                                requiredErrorMsg={data?.errorMessageContent.passwordRequiredMsg}
                                errorMsg={{
                                }}
                            />
                        </GelFormField>
                        <GelFormField
                            label={data?.confirmPassword}
                        >
                            <GelPasswordInput
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={onFormDataChange}
                                onInput={handleConfirmPasswordInput}
                                required
                                errorMsg={{
                                    required: data?.errorMessageContent.passwordRequiredMsg
                                }}
                            />

                            <GelParagraph style={{ color: themeColorBackgroundDanger, fontSize: sizeBaseX3, paddingTop: sizeBaseUnit }}>
                                {!passwordsMatch && data?.errorMessageContent.passwordMatchMsg}
                            </GelParagraph>
                        </GelFormField>
                        <GelButton
                            name="" style={{
                                width: "100%",
                            }}
                            primary large submit
                        >
                            {data?.buttonText}
                        </GelButton>

                        <GelBoxLayout space="auto">
                            {loginStatusMessage &&
                                <GelLabel style={{ color: themeColorTextDanger }}>
                                    <GelIcon color={themeColorTextDanger} name="AlertCircle" inline />
                                    {data?.errorMessageContent.passwordReqMsg}
                                </GelLabel>}
                        </GelBoxLayout>
                    </GelRowLayout>
                </GelForm>
                {isLargerThanSmScreen(screen) &&
                    <FundBanner />}
            </GelBoxLayout>
        </GelContainerLite>
    )
};

export default ResetPassword;