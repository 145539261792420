import React from "react";
import {GelRowLayout, GelLabel} from "@tal-gel/components";
import {getGelTokens} from "@tal-gel/theming";
import {useGetFundBannerDetailsContent} from "../common/api/graphQLDataFetch";

const FundBanner = () => {
    const {
        global: {
            themeColorBackgroundInverse,
            themeColorBackgroundDefault,
            fontSizeBodyXLarge,
            sizeBaseUnit,
            fontSizeDisplayLarge,
            fontFamilySans,
            fontSizeDisplayMedium,
            fontWeightBold,
            themeColorTextInverse,
        },
    } = getGelTokens();

    const {data} = useGetFundBannerDetailsContent();

    return (
        <div
            style={{
                backgroundColor: themeColorBackgroundInverse,
                color: themeColorBackgroundDefault,
                fontSize: fontSizeDisplayLarge,
                fontFamily: fontFamilySans,
                marginRight: -sizeBaseUnit * 4,
                position: "relative",
                height: "70vh",
            }}
        >
            <GelRowLayout gutter="xsmall">
                <GelLabel
                    style={{
                        fontSize: fontSizeDisplayMedium,
                        fontWeight: fontWeightBold,
                        display: "inline-block",
                        marginLeft: sizeBaseUnit * 10,
                        color: themeColorTextInverse,
                        paddingTop: sizeBaseUnit * 10,
                        paddingRight: sizeBaseUnit * 10,
                    }}
                >
                    {data?.headerText}
                </GelLabel>

                <GelLabel
                    style={{
                        fontWeight: 400,
                        fontSize: fontSizeBodyXLarge,
                        marginLeft: sizeBaseUnit * 10,
                        color: themeColorTextInverse,
                        position: "absolute",
                        paddingRight: sizeBaseUnit * 20,
                    }}
                >
                    {data?.subHeaderText}
                </GelLabel>
            </GelRowLayout>
        </div>
    );
};

export default FundBanner;
