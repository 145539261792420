import React, { useContext, useEffect } from 'react';
import {
    GelScreenDetectorContext,
    GelRowLayout,
    GelContainerLite,
    GelHeading2,
    GelParagraph,
    GelSpinner
} from '@tal-gel/components';
import SupportLovedOnes from './supportLovedOnes';
import SupportInRecovery from './supportInRecovery';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../common/usercontext/user.context';
import HealthConnectorCampaign from './healthConnectorCampaign';
import { getGelTokens } from '@tal-gel/theming';
import styled from '@emotion/styled';
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';
import { useGetHealthAndSupportPageContent } from '../../common/api/graphQLDataFetch';

const SupportContainer = () => {
    const { global: { sizeBaseX15, sizeBaseX10, sizeBaseX3, sizeNone, sizeBaseX5, sizeBaseX2, themeColorBackgroundLight } } = getGelTokens();

    const navigate = useNavigate();
    const { contextData } = useContext(UserContext)
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const [{ tpid }] = useCookies(['tpid']);
    const { data, isFetched } = useGetHealthAndSupportPageContent()

    if (contextData.memberDetails?.displayLegalDisclosure)
        navigate("/yourDeclaration");

    useEffect(() => {
        AdobeAnalytics.PushFormStepEvent(tpid)
        AdobeAnalytics.PushPageViewEvent(tpid)
    }, []);

    if (!isFetched)
        return <GelSpinner medium overlay />

    const Spacer = styled.div(() => ({
        paddingTop: sizeBaseX2
    }));

    const showHealthConnector = data?.healthConnectorCampaignImage;

    const PageHeader = styled.div(() => ({
        padding: isXsScreen(screen) ? `${sizeBaseX15}px ${sizeBaseX5}px` : `${sizeBaseX15}px ${sizeBaseX10}px`,
        backgroundColor: themeColorBackgroundLight
    }));

    return (
        <GelContainerLite style={{ padding: sizeNone, overflow: "hidden" }}>
            <PageHeader >
                <GelHeading2>
                    {data?.pageHeader}
                </GelHeading2>

                <GelParagraph style={{ paddingTop: sizeBaseX3 }}>{data?.pageSubtext}</GelParagraph>
            </PageHeader>

            <GelRowLayout style={{ padding: isXsScreen(screen) ? `${sizeBaseX10}px ${sizeBaseX5}px` : `${sizeBaseX5}px ${sizeBaseX10}px` }}>
                <SupportLovedOnes />
            </GelRowLayout>

            {showHealthConnector ? <HealthConnectorCampaign /> : <Spacer />}

            <GelRowLayout style={{ padding: isXsScreen(screen) ? `${sizeBaseX10}px ${sizeBaseX5}px` : `${sizeBaseX5}px ${sizeBaseX10}px` }}>
                <SupportInRecovery />
            </GelRowLayout>

        </GelContainerLite>
    );
};

export default SupportContainer;