import {
    GelRowLayout,
    GelSpinner,
    GelParagraph,
    GelLink,
    GelCollapsible,
    GelContainerLite,
    GelBoxLayout,
    GelLabel,
    GelIcon
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { UserContext } from '../../../common/usercontext/user.context';
import axios from 'axios';
import { AdobeClickType, API } from '../../../constants/constants';
import { MARK_NOTIFICATION_READ } from '../../../graphql/queries/graphql-mutations';
import SESSION_STORAGE_KEY from '../../../constants/storage.constant';
import { AdobeAnalytics } from '../../../common/analytics/adobe-analytics';

const ProcessedPayment = (props) => {
    const { global: { themeColorBorderDefault, sizeBaseUnit, fontFamilySans, fontWeightRegular, themeColorIconDefault } } = getGelTokens();

    const [{ aid, rid, ssoid, tpid }] = useCookies(['aid', 'rid', 'ssoid', 'tpid']);
    const { contextData, setUserContext } = useContext(UserContext);
    const [notificationUpdating, setnotificationUpdating] = useState(false);

    const closeNofitication = (refreshData: boolean) => {
        AdobeAnalytics.PushClickEvent(tpid, "Close", AdobeClickType.Link)

        let requestData = {
            notificationIds: [props.notificationId]
        };

        if (refreshData)
            setnotificationUpdating(true);

        axios.post(API.BASEURL, {
            query: MARK_NOTIFICATION_READ,
            variables: {
                markNotificationRequest: requestData
            }
        },
            {
                headers: {
                    'x-aid': aid,
                    'x-rid': rid,
                    ssoid,
                    tpid,
                    'sid': window.sessionStorage.getItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN)
                }
            })
            .then(async (response) => {
                if (refreshData) {
                    updateContext(response.data.data.markNotificationsRead.list[0]);
                    setnotificationUpdating(false);
                }
            })
    }

    const updateContext = (notification) => {
        let claim = contextData.claims.filter(claim => claim.claimId == notification?.claimId)[0];
        if (claim) {
            let index = claim.requirements.findIndex(req => req.id == notification?.notificationId);
            if (index >= 0)
                claim.requirements.splice(index, 1);

            claim.activities.filter(at => at.claimPaymentId == notification.sourceId).map(x => x.hideActivity = false);
            setUserContext({ claims: contextData.claims, yourDetails: contextData.yourDetails, memberDetails: contextData.memberDetails });
        }
    }
    const [showProcessedPayment, setShowProcessedPayment] = useState(false);

    const onHeaderClick = () => {
        setShowProcessedPayment(!showProcessedPayment);
        AdobeAnalytics.PushClickEvent(tpid, props.contentData?.paymentMadeHeader, AdobeClickType.Button)

        closeNofitication(false);
    }

    return (
        <div id="processedPayment">
            <GelContainerLite style={{
                borderTop: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                borderLeft: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                borderRight: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                paddingTop: sizeBaseUnit,
                paddingBottom: sizeBaseUnit,
                width: "auto",
                cursor: "pointer"
            }}
                onClick={onHeaderClick}>

                <GelBoxLayout space={[7, 0.1, 0.04]} style={{
                    borderBottom: showProcessedPayment ? `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}` : "",
                    width: "auto",
                    padding: sizeBaseUnit * 2,
                    marginLeft: -sizeBaseUnit * 4,
                    marginRight: -sizeBaseUnit * 4,
                }}>
                    <GelLabel style={{
                        fontFamily: fontFamilySans,
                        fontSize: sizeBaseUnit * 4,
                        fontWeight: fontWeightRegular
                    }}>
                        {props.contentData?.paymentMadeHeader}
                    </GelLabel>

                    <GelIcon name={showProcessedPayment ? "ChevronUp" : "ChevronDown"} color={themeColorIconDefault} width="20px" height="20px" />
                </GelBoxLayout>
            </GelContainerLite>

            <GelContainerLite style={{
                borderBottom: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                borderLeft: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                borderRight: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                width: "auto"
            }}>

                <GelCollapsible open={showProcessedPayment}>
                    <GelContainerLite style={{ padding: sizeBaseUnit * 4 }}>
                        <GelRowLayout>
                            <GelParagraph>
                                {props.payment?.description}
                            </GelParagraph>
                        </GelRowLayout>
                        <GelRowLayout style={{ paddingTop: sizeBaseUnit * 6 }} >
                            {notificationUpdating && <GelSpinner small />}
                            {!notificationUpdating &&
                                <GelLink onClick={() => closeNofitication(true)}>Close</GelLink>}
                        </GelRowLayout>
                    </GelContainerLite>
                </GelCollapsible>

            </GelContainerLite>
        </div>
    )
}

export default ProcessedPayment;