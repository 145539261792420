import React, { useContext, useEffect, useState } from 'react';
import { CLAIM_ASSESSMENT_STATUS } from '../../constants/constants';
import Card from '../../common/components/card';
import {
    GelScreenDetectorContext,
    GelContainerLite,
    GelParagraph,
    GelHeading2,
    GelSpinner,
    GelBoxLayout,
    GelIcon
} from '@tal-gel/components';
import Requirement from './requirement';
import BenefitType from './benefitType';
import NextPayment from './nextPayment';
import { getGelTokens } from '@tal-gel/theming';
import { UserContext } from '../../common/usercontext/user.context';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import DocumentUpload from './documentupload';
import ResponsiveContainer from "../../components/responsive.container";
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';
import { useGetHomePageContent } from "../../common/api/graphQLDataFetch"
import { useCookies } from 'react-cookie';

export enum AlertTypeEnum {
    Info = "info",
    Danger = "danger",
    Success = "success",
    Warning = "warning"
}

const ClaimsContainer = () => {
    const { global: { sizeNone, themeColorBackgroundLight, sizeBaseX15, sizeBaseX5, sizeBaseX10, sizeBaseX3, sizeBaseX6,
        themeColorTextDark, themeColorBackgroundAccent, themeColorIconDark, fontSizeBodySmall, themeColorBackgroundDanger,
        themeColorBackgroundWarning, themeColorBackgroundSuccess, themeColorIconInverse, sizeBaseX9, sizeBaseX4
    } } = getGelTokens();
    const { data, isFetched } = useGetHomePageContent()

    const navigate = useNavigate();
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const { contextData } = useContext(UserContext);
    const [{ tpid }] = useCookies();

    if (contextData.memberDetails?.memberNumber &&
        (contextData?.claims?.length === 0 || !contextData?.claims)) {
        navigate("/error");
    }

    useEffect(() => {
        if (tpid) {
            AdobeAnalytics.PushFormStartEvent(tpid);
            AdobeAnalytics.PushPageViewEvent(tpid);
        }
    }, [tpid]);

    const PageHeader = styled.div(() => ({
        padding: isXsScreen(screen) ? `${sizeBaseX15}px ${sizeBaseX5}px` : `${sizeBaseX15}px ${sizeBaseX10}px`,
        backgroundColor: themeColorBackgroundLight
    }));

    const getAlertDetails = (alertType: AlertTypeEnum) => {
        switch (alertType) {
            case AlertTypeEnum.Info:
                return <GelBoxLayout style={{ backgroundColor: themeColorBackgroundAccent, padding: isXsScreen(screen) ? `${sizeBaseX3}px ${sizeBaseX4}px` : `${sizeBaseX3}px ${sizeBaseX10}px` }}
                    space={[0.5, 10]}>
                    <GelIcon name="InfoCircle"
                        width={sizeBaseX6}
                        color={themeColorIconDark} />
                    <GelParagraph style={{ fontSize: fontSizeBodySmall, color: themeColorTextDark }}>
                        {data?.alertDetails[0]?.AlertMessage}</GelParagraph>
                </GelBoxLayout>
            case AlertTypeEnum.Danger:
                return <GelBoxLayout style={{ backgroundColor: themeColorBackgroundDanger, padding: isXsScreen(screen) ? `${sizeBaseX3}px ${sizeBaseX4}px` : `${sizeBaseX3}px ${sizeBaseX10}px` }}
                    space={[0.5, 10]}>
                    <GelIcon name="AlertTriangle"
                        width={sizeBaseX6}
                        color={themeColorIconInverse} />
                    <GelParagraph style={{ fontSize: fontSizeBodySmall, color: themeColorIconInverse }}>
                        {data?.alertDetails[0]?.AlertMessage}</GelParagraph>
                </GelBoxLayout>
            case AlertTypeEnum.Success:
                return <GelBoxLayout style={{ backgroundColor: themeColorBackgroundSuccess, padding: isXsScreen(screen) ? `${sizeBaseX3}px ${sizeBaseX4}px` : `${sizeBaseX3}px ${sizeBaseX10}px` }}
                    space={[0.5, 10]}>
                    <GelIcon name="CheckSmall"
                        width={sizeBaseX6}
                        color={themeColorIconDark} />
                    <GelParagraph style={{ fontSize: fontSizeBodySmall, color: themeColorTextDark }}>
                        {data?.alertDetails[0]?.AlertMessage}</GelParagraph>
                </GelBoxLayout>
            case AlertTypeEnum.Warning:
                return <GelBoxLayout style={{ backgroundColor: themeColorBackgroundWarning, padding: isXsScreen(screen) ? `${sizeBaseX3}px ${sizeBaseX4}px` : `${sizeBaseX3}px ${sizeBaseX10}px` }}
                    space={[0.5, 10]} >
                    <GelIcon name="AlertCircle"
                        width={sizeBaseX6}
                        color={themeColorIconDark} />
                    <GelParagraph style={{ fontSize: fontSizeBodySmall, color: themeColorTextDark }}>
                        {data?.alertDetails[0]?.AlertMessage}</GelParagraph>
                </GelBoxLayout>
            default:
                return <></>;
        }
    };

    if (contextData.memberDetails?.displayLegalDisclosure)
        navigate("/yourDeclaration");


    const claimPadding = (index) => {
        if (isXsScreen(screen))
            return `0px ${sizeBaseX5}px ${sizeBaseX5}px ${sizeBaseX5}px`
        else
            return `0px ${sizeBaseX10}px ${index === contextData.claims.length - 1 ? sizeNone : sizeBaseX5}px ${sizeBaseX10}px`
    }

    if (!isFetched) {
        <GelSpinner medium overlay />;
    }

    return (
        <GelContainerLite style={{ padding: sizeNone, overflow: "hidden" }}>
            {contextData.claims?.length > 0 &&
                <GelContainerLite style={{ padding: sizeNone }}>
                    {data?.alertDetails && data?.alertDetails[0]?.DisplayAlert &&
                        getAlertDetails(data?.alertDetails[0]?.AlertType)}
                    <PageHeader >
                        <GelHeading2>{data?.appTitle}</GelHeading2>
                        <GelParagraph style={{ paddingTop: sizeBaseX3 }}>{data?.appTitleDescription}</GelParagraph>
                    </PageHeader>
                </GelContainerLite>
            }
            <ResponsiveContainer style={{ paddingTop: `${sizeBaseX15}px`, paddingBottom: `${sizeBaseX15}px` }}>
                {contextData?.claims?.map((claim, index) => (
                    <div key={claim.claimNo} style={{ padding: claimPadding(index) }} >
                        <Card title={claim.claimTypeName} claimAssesmentStatus={claim?.claimAssesmentStatus}
                            buttonText={data?.buttonText} icon={"InfoCircle"}
                            subTitle={data?.subTitle} showFooter={true}>
                            <BenefitType claimNumber={claim.caseNumber} description={claim?.status.description} friendlyStatus={claim?.status.friendlyStatus}
                                benefitTypeText={claim.claimTypeName} isFromHomePage={true} />

                            {(claim?.claimAssesmentStatus !== CLAIM_ASSESSMENT_STATUS.RN) &&
                                <Requirement requirementList={claim.requirements.filter(rq => rq.type !== 1 && rq.type !== 3 && rq.type !== 4)}
                                    thirdPartyRequirement={claim?.status.thirdPartyRequirementInfoText} claimType={claim.claimTypeName}
                                    requirementStatus={data?.requirementCompletedStatus}
                                    thirdPartyRequirementStatus={data?.thirdPartyRequirementStatus}
                                    requirementInCompleteStatus={data?.requirementInCompleteStatus}
                                    requirementReviewStatus={data?.requirementReviewStatus} />}

                            {(claim?.claimAssesmentStatus !== CLAIM_ASSESSMENT_STATUS.RN) &&
                                <NextPayment message={data?.paymentMessage}
                                    isFuturePayment={claim.nextFuturePayment.isSuccess}
                                    futurePayments={claim.nextFuturePayment.value}
                                    claimType={claim.claimTypeName} />}

                            {(claim?.claimAssesmentStatus === CLAIM_ASSESSMENT_STATUS.RN)
                                &&
                                <DocumentUpload title={claim.claimTypeName} uploadDocumentText={data?.uploadDocumentText} uploadDocumentDescriptor={data?.uploadDocumentDescriptor} />}
                        </Card>
                    </div>
                ))}
            </ResponsiveContainer>
        </GelContainerLite>
    );
};

export default ClaimsContainer;