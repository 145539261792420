export enum OktaErrors {
    /**
     * errorSummary: "You have accessed an account recovery link that has expired or been previously used."
     */
    EXPIRED_OR_USED_ACTIVATION_LINK = "E0000105",
    /**
     * errorSummary: "You have accessed an account recovery link that has expired or been previously used."
     */
    EXPIRED_OR_USED_RESET_PASSWORD_LINK = "E0000105"
}
