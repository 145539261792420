import numeral from 'numeral';
import {
    GelIcon,
    GelParagraph,
    GelRow,
    GelCol,
    GelSpinner,
    GelScreenDetectorContext,
    GelBoxLayout,
    GelTag,
    GelLabel,
    GelRowLayout,
    GelContainerLite
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { ChangeEvent, memo, useContext, useEffect, useRef, useState } from "react";
import { API, AcceptedFileTypes, AdobeClickType, AllowedFileCountPerUpload, AllowedMaxFileSize, FILE_UPLOAD_STATUS, supportedFileTypes } from "../../../constants/constants"
import React from "react";
import { SCAN_DOCUMENT_QUERY } from '../../../graphql/queries/graphql-queries.constant';
import axios from "axios";
import { UPLOAD_DOCUMENT_MUTATION } from "../../../graphql/queries/graphql-mutations";
import { useCookies } from "react-cookie";
import DocumentDownload from "../documentDownload/documentDownload";
import { UserContext } from "../../../common/usercontext/user.context";
import { DocumentModel } from "../../../common/models/document.model";
import { FileUploadModel } from "../../../common/models/files";
import SESSION_STORAGE_KEY from '../../../constants/storage.constant';
import styled from "@emotion/styled";
import { AdobeAnalytics } from '../../../common/analytics/adobe-analytics';


interface UploaderProps {
    requirement?: DocumentModel;
    contentFullData: any;
    setAllowCollapse: (allowCollapse: boolean) => void;
}


const Uploader = (props: UploaderProps) => {
    const { global: { sizeBaseUnit, themeColorBackgroundDefault, themeColorBorderSelected, sizeBaseX6, themeColorIconDanger,
        themeColorTextDanger, sizeBaseX5, themeColorTextLink, themeColorTextLight, themeColorBlack, sizeBaseX16, sizeBaseX2 },
        brand: { brandColorPrimary1, brandColorPrimary1T20, brandColorPrimary1T10, brandColorPrimary2 } } = getGelTokens();

    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const [isExpandFiles, expandFileList] = useState(false);
    const [fileList, setFileList] = useState<any[]>([]);
    const [uploadedFileList] = useState<any[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [{ aid, rid, ssoid, tpid }] = useCookies(['aid', 'rid', 'ssoid', 'tpid']);
    const [showMaxFileCountError, setShowMaxFileCountError] = useState(false);
    const { contextData, setUserContext } = useContext(UserContext);
    const [reloadIcons, setReloadIcons] = useState(false);
    const [isHover, setIsHover] = useState(false);

    let files = fileList ? [...fileList] : [];

    const [uploadedFiles, setUploadedFiles] = useState<FileUploadModel[]>([]);

    useEffect(() => {
        if (props.requirement?.associatedDocuments) {
            if (props.requirement.associatedDocuments.length > 0) {
                setFileList([...fileList, ...props.requirement.associatedDocuments.map(toFile)]);
                props.requirement.associatedDocuments.forEach((file: any) => {
                    setFilesData(toFile(file), FILE_UPLOAD_STATUS.SUCCESS);
                });
            }
        }
    }, []);

    const uploadContainerStyle = {
        borderRadius: sizeBaseUnit * 2,
        backgroundColor: brandColorPrimary1T10,
        cursor: isUploading ? "none" : "pointer",
        border: isHover ? `2px dashed ${themeColorBorderSelected}` : "none"
    }

    const UploadIconContainer = styled.div`
        padding-top: ${sizeBaseUnit * 4};
        height: ${sizeBaseUnit * 10}px;
        width: ${sizeBaseUnit * 10}px;
        background-color: ${isHover ? brandColorPrimary1 : themeColorBackgroundDefault};
        border-radius: 50%;
        border: 4px solid ${brandColorPrimary1T20};
        display: inline-block;
        position: relative;
    `;


    const handleMouseEnter = () => {
        if (!isUploading)
            setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };

    const toFile = (file: any) => {
        return {
            id: Math.random(),
            status: FILE_UPLOAD_STATUS.SUCCESS,
            name: file.displayName,
            sharePointDocumentIdentifier: file.sharePointDocumentIdentifier,
            displayName: file.displayName
        };
    };

    const handleDeleteFileClick = (fileDetail: any) => {
        setIsUploading(false);
        props.setAllowCollapse(true);

        if (fileDetail && fileDetail.size > 0 && !fileDetail.isSuccess) {
            files = files.filter(file => file.name !== fileDetail.name);
        }
        setFileList(files);
    }

    const handleUploadClick = () => {
        AdobeAnalytics.PushClickEvent(tpid, props.contentFullData.otherDocumentsAccordionTitle, AdobeClickType.Button)

        if (isUploading)
            return;

        inputRef.current?.click();
        if (!fileList) {
            return;
        }
    };

    const handleFileUploadComplete = (validFiles) => {
        setIsUploading(false);
        props.setAllowCollapse(true);

        //reloading status icons in case if any file upload failed
        if (uploadedFiles.findIndex(f => f.status == FILE_UPLOAD_STATUS.FAILED) >= 0)
            setReloadIcons(!reloadIcons);

        validFiles.forEach(file => {
            if (file && file.fileStatus !== FILE_UPLOAD_STATUS.VULNERABLE && file.fileStatus !== FILE_UPLOAD_STATUS.FAILED) {
                contextData.claims.forEach(claim => {
                    if (props.requirement?.friendlyText && props.requirement?.friendlyText !== '') {
                        claim.requirements.filter(req => req.friendlyText === props.requirement?.friendlyText)[0]?.associatedDocuments?.push({
                            documentName: file.fileName, displayName: file.fileName,
                            sharePointDocumentIdentifier: file.sharePointDocumentIdentifier
                        });
                    }
                })

                if (!props.requirement?.friendlyText || props.requirement?.friendlyText === '') {

                    contextData.otherDocuments?.push({
                        claimId: props.requirement?.claimId!,
                        documentName: file.fileName,
                        displayName: file.fileName,
                        sharePointDocumentIdentifier: file.sharePointDocumentIdentifier,
                        isAssociatedRequirementFound: false
                    });
                }

                setUserContext(contextData);
            }
        });
    };

    const uploadFiles = (files: any) => {
        let scannedUploads = 0;
        let invalidUploads = 0;
        expandFileList(true);
        setIsUploading(true);
        props.setAllowCollapse(false);


        AdobeAnalytics.PushClickEvent(tpid, props.contentFullData.otherDocumentsAccordionTitle, AdobeClickType.Button)

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (!file.identifier)
                file.identifier = Math.random().toString();

            const errorStatus = checkFileIsValid(file);
            if (errorStatus) {

                setFilesData(file, errorStatus);
                invalidUploads++;
                continue;
            }

            // eslint-disable-next-line no-loop-func
            const uploadFile = async () => {
                try {

                    setFilesData(file, "");
                    const data = new FormData();
                    data.append(`0`, file);
                    data.append(
                        "operations",
                        JSON.stringify({
                            query: UPLOAD_DOCUMENT_MUTATION,
                            variables: {
                                file: file,
                                requirements: props.requirement?.description ?? "",
                            },
                        })
                    );
                    data.append("map", JSON.stringify({ "0": ["variables.file"] }));

                    const uploadResponse = await axios.post(
                        `${API.BASEURL}`,
                        data,
                        {
                            headers: {
                                "GraphQL-preflight": 1,
                                "Content-Type": "multipart/form-data",
                                'x-aid': aid,
                                'x-rid': rid,
                                ssoid,
                                tpid,
                                'sid': window.sessionStorage.getItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN)
                            },
                        }
                    );

                    if (uploadResponse.data.data.uploadDocument) {

                        setFilesData(file, uploadResponse.data.data.uploadDocument.scanStatus.toLowerCase());

                        const getFileStatuses = async () => {
                            axios.post(API.BASEURL, {
                                query: SCAN_DOCUMENT_QUERY,
                                variables: {
                                    fileScanId: uploadResponse.data.data.uploadDocument.id,
                                    claimId: props.requirement?.claimId
                                }
                            },
                                {
                                    headers: {
                                        'x-aid': aid,
                                        'x-rid': rid,
                                        ssoid,
                                        tpid,
                                        'sid': window.sessionStorage.getItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN)
                                    }
                                })
                                .then(async (response) => {
                                    const { scanStatus, documentIdentifier, displayName, id } = response.data.data.uploadDocumentConfirmation

                                    if (!scanStatus ||
                                        (scanStatus.toLowerCase() !== FILE_UPLOAD_STATUS.SUCCESS &&
                                            scanStatus.toLowerCase() !== FILE_UPLOAD_STATUS.VULNERABLE &&
                                            scanStatus.toLowerCase() !== FILE_UPLOAD_STATUS.FAILED)) {
                                        await new Promise((f) => setTimeout(f, 3000));
                                        getFileStatuses();
                                    } else {

                                        if (scanStatus.toLowerCase() === FILE_UPLOAD_STATUS.FAILED) {
                                            setFilesData(file, FILE_UPLOAD_STATUS.FAILED);
                                            setIsUploading(false);
                                            scannedUploads++;
                                        }

                                        file.sharePointDocumentIdentifier = documentIdentifier;
                                        file.displayName = displayName;

                                        setFilesData(file, scanStatus.toLowerCase());
                                        const newFiles = {
                                            fileScanId: id,
                                            fileName: file.name,
                                            fileStatus: scanStatus.toLowerCase(),
                                            sharePointDocumentIdentifier: documentIdentifier
                                        };
                                        uploadedFileList.push(newFiles);

                                        let validFiles: any[] = [];

                                        if (uploadedFileList.length > 0) {
                                            validFiles = uploadedFileList.filter(
                                                (file: any) => (file.fileStatus.toLowerCase() === FILE_UPLOAD_STATUS.SUCCESS ||
                                                    file.fileStatus.toLowerCase() === FILE_UPLOAD_STATUS.VULNERABLE)
                                            );
                                            if (validFiles.length > 0) {
                                                scannedUploads++;
                                            }
                                        }

                                        if ((scannedUploads + invalidUploads) === files.length) {
                                            handleFileUploadComplete(validFiles);
                                        }
                                    }
                                })
                                .catch((error) => {
                                    console.error(error)
                                    setFilesData(file, FILE_UPLOAD_STATUS.FAILED);

                                    scannedUploads++;
                                    setIsUploading(false);
                                    props.setAllowCollapse(true);
                                    if ((scannedUploads + invalidUploads) === files.length) {
                                        handleFileUploadComplete(null);
                                    }
                                })
                        };

                        getFileStatuses();
                    }

                } catch {

                    setFilesData(file, FILE_UPLOAD_STATUS.FAILED);
                    setIsUploading(false);
                    props.setAllowCollapse(true);

                    scannedUploads++;
                }
            };

            uploadFile();
        }
    };

    const isFileCountExceeded = (fileCount: number): boolean => {
        if (fileCount > AllowedFileCountPerUpload) {
            setShowMaxFileCountError(true);
            return true;
        }
        else
            return false;
    }
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files)
            return;

        if (!isFileCountExceeded(e.target.files.length)) {
            setFileList([...fileList, ...e.target.files]);
            uploadFiles(e.target.files);
        }
    };

    const handleDragOver = (event: { preventDefault: () => void }) => {
        event.preventDefault();
    };

    const handleDragEnter = (e) => {
        console.log("handleDragEnter true")
        e.preventDefault();
        if (!isUploading)
            setIsHover(true);
    };

    const handleDragLeave = () => {
        console.log("handleDragLeave false")

        setIsHover(false);
    };

    const handleDrop = (event: any) => {
        if (!isUploading) {
            event.preventDefault();
            const dataTransferItems = event.dataTransfer.items;
            const droppedFilesArray: any[] = [];
            for (let i = 0; i < dataTransferItems.length; i++) {
                const item = dataTransferItems[i];
                if (item.kind === "file") {
                    droppedFilesArray.push(item.getAsFile());
                }
            }

            if (!isFileCountExceeded(droppedFilesArray.length)) {
                setFileList([...fileList, ...droppedFilesArray]);
                uploadFiles(droppedFilesArray);
            }
        }
    };

    const hasSupportedExtension = (name: string) => {
        return supportedFileTypes.some((extension) => {
            var lastIndex = name.toLocaleLowerCase().lastIndexOf(extension);
            return lastIndex !== -1 && lastIndex + extension.length === name.length;
        });
    };

    const checkFileType = (file: any) => {
        if (!hasSupportedExtension(file.name)) {
            return FILE_UPLOAD_STATUS.NOT_SUPPORTED;
        }
        return null;
    };

    const checkFileSize = (file: any) => {
        if (file.size > AllowedMaxFileSize) {
            return FILE_UPLOAD_STATUS.FILE_SIZE_EX;
        }
        return null;
    };

    const formatFileSize = (file: any) => {
        return file?.size > 0 ? numeral(file?.size).format('0 b') : "";
    }

    const checkFileIsValid = (file: any) => {
        return (checkFileType(file) === FILE_UPLOAD_STATUS.NOT_SUPPORTED) ? FILE_UPLOAD_STATUS.NOT_SUPPORTED :
            (checkFileSize(file) === FILE_UPLOAD_STATUS.FILE_SIZE_EX) ? FILE_UPLOAD_STATUS.FILE_SIZE_EX : null;
    };

    const setFilesData = (file: any, status: string) => {

        if (file.sharePointDocumentIdentifier) {

            if (uploadedFiles.findIndex(f => f.sharePointDocumentIdentifier == file.sharePointDocumentIdentifier) >= 0)
                uploadedFiles.filter(f => f.sharePointDocumentIdentifier == file.sharePointDocumentIdentifier)[0].status = status;
            else {
                if (uploadedFiles.findIndex(f => f.identifier == file.identifier) >= 0) {
                    uploadedFiles.filter(f => f.identifier == file.identifier)[0].status = status;
                    uploadedFiles.filter(f => f.identifier == file.identifier)[0].sharePointDocumentIdentifier = file.sharePointDocumentIdentifier;
                }
                else {
                    uploadedFiles.push({
                        identifier: Math.random().toString(),
                        sharePointDocumentIdentifier: file.sharePointDocumentIdentifier,
                        name: file.name,
                        status: status,
                        size: formatFileSize(file)
                    });
                }
            }
        } else {
            if (uploadedFiles.findIndex(f => f.identifier == file.identifier) >= 0)
                uploadedFiles.filter(f => f.identifier == file.identifier)[0].status = status;
            else {
                uploadedFiles.push({
                    identifier: file.identifier,
                    sharePointDocumentIdentifier: file.sharePointDocumentIdentifier,
                    name: file.name,
                    status: status,
                    size: formatFileSize(file)
                });
            }
        }

        setUploadedFiles(uploadedFiles);
    };

    const getIcon = (file: any) => {

        let status: string | undefined = "";

        if (file.sharePointDocumentIdentifier) {
            status = uploadedFiles.find(f => f.sharePointDocumentIdentifier == file.sharePointDocumentIdentifier)?.status;
        }
        else {
            status = uploadedFiles.find(f => f.identifier == file.identifier)?.status;
        }

        if (status === FILE_UPLOAD_STATUS.SUCCESS || status === FILE_UPLOAD_STATUS.SANITISED) {
            return (
                <GelIcon
                    name="Attachment"
                    width={sizeBaseX6}
                    height={sizeBaseX6}
                />
            );
        } else if (
            status === FILE_UPLOAD_STATUS.VULNERABLE || status === FILE_UPLOAD_STATUS.FAILED ||
            status === FILE_UPLOAD_STATUS.NOT_SUPPORTED || status === FILE_UPLOAD_STATUS.FILE_SIZE_EX
        ) {
            console.log("status received", status)
            return (<GelIcon
                name="AlertCircle"
                width={sizeBaseX6}
                height={sizeBaseX6}
                color={themeColorIconDanger}
            />);
        } else {
            console.log("unknown status received", status)
            return <GelSpinner small overlay={false} />;
        }
    };

    const getMessage = (file: any) => {

        let status: string | undefined = "";
        let size: string | undefined = "";

        if (file.sharePointDocumentIdentifier) {
            size = uploadedFiles.find(f => f.sharePointDocumentIdentifier == file.sharePointDocumentIdentifier)?.size;
            status = uploadedFiles.find(f => f.sharePointDocumentIdentifier == file.sharePointDocumentIdentifier)?.status;
        }
        else {
            size = uploadedFiles.find(f => f.identifier == file.identifier)?.size;
            status = uploadedFiles.find(f => f.identifier == file.identifier)?.status;
        }

        if (status === "" || status === undefined || status === FILE_UPLOAD_STATUS.SCANNING) {
            return (<GelParagraph>{size ? "Uploading... | " + size : "Uploading"}</GelParagraph>
            );
        } else if (status === FILE_UPLOAD_STATUS.SUCCESS || status === FILE_UPLOAD_STATUS.SANITISED) {
            return "";
        } else if (status === FILE_UPLOAD_STATUS.VULNERABLE || status === FILE_UPLOAD_STATUS.FAILED) {
            return (<GelParagraph style={{ color: themeColorIconDanger }}>
                {props.contentFullData?.validationErrors?.unSafeFileError}</GelParagraph>
            );
        }
        else if (status === FILE_UPLOAD_STATUS.FILE_SIZE_EX) {
            return (<GelParagraph style={{ color: themeColorIconDanger }}>
                {props.contentFullData?.validationErrors?.maxSizeError}</GelParagraph>
            );
        }
        else if (status === FILE_UPLOAD_STATUS.NOT_SUPPORTED) {
            return (<GelParagraph style={{ color: themeColorIconDanger }}>
                {props.contentFullData?.validationErrors?.unSupportedFileError}</GelParagraph>
            );
        }
        else
            return "";
    }

    const showBinIcon = (file: any) => {

        let status: string | undefined = "";

        if (file.sharePointDocumentIdentifier)
            status = uploadedFiles.find(f => f.sharePointDocumentIdentifier == file.sharePointDocumentIdentifier)?.status;
        else
            status = uploadedFiles.find(f => f.identifier == file.identifier)?.status;

        if (
            status === "" ||
            status === undefined ||
            status === FILE_UPLOAD_STATUS.SUCCESS ||
            status === FILE_UPLOAD_STATUS.SANITISED ||
            status === FILE_UPLOAD_STATUS.SCANNING
        ) {
            return false;
        } else return true;
    };
    const onFileHideClick = () => {
        expandFileList(!isExpandFiles)
    }


    return (
        <>
            {showMaxFileCountError &&
                <GelParagraph style={{ color: themeColorTextDanger }}>
                    {props.contentFullData?.validationErrors.maxFileCountError}
                </GelParagraph>
            }
            <GelContainerLite
                style={uploadContainerStyle}
                onClick={handleUploadClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
            >
                <div style={{
                    paddingTop: "16px",
                    paddingBottom: "16px"
                }}>
                    <GelRowLayout alignment="center">
                        <UploadIconContainer>
                            <GelIcon
                                name="Upload"
                                style={{
                                    width: sizeBaseX5,
                                    position: "absolute",
                                    top: "50%", left: "50%",
                                    transform: "translate(-50%, -50%)"
                                }}
                                color={isHover ? themeColorBackgroundDefault : brandColorPrimary2}
                            />
                        </UploadIconContainer>
                        <GelParagraph  {...!isXsScreen(screen) && {
                            style: {
                                color: themeColorTextLink
                            }
                        }}>
                            {props.contentFullData?.selectFiles}
                        </GelParagraph>
                        <GelParagraph  {...!isXsScreen(screen) && {
                            style: {
                                color: themeColorTextLight
                            }
                        }}>
                            {" "}
                            {props.contentFullData?.dragFiles}
                        </GelParagraph>
                    </GelRowLayout>
                </div>
            </GelContainerLite>

            <div style={{ paddingTop: "8px" }}>
                {files?.length > 0 &&
                    <GelBoxLayout distribution='left' space={[0.1, 0.1, 0.1]}>
                        {!isExpandFiles &&
                            <GelIcon
                                name="MinimalDown"
                                width={sizeBaseUnit * 6}
                                color={themeColorBlack}
                                style={{ float: "left" }}
                                marginRight={-sizeBaseUnit * 2}
                                onClick={() => onFileHideClick()}
                            ></GelIcon>}
                        {isExpandFiles &&
                            <GelIcon
                                name="MinimalUp"
                                width={sizeBaseUnit * 6}
                                color={themeColorBlack}
                                style={{ float: "left" }}
                                marginRight={-sizeBaseUnit * 2}
                                onClick={() => onFileHideClick()}
                            ></GelIcon>}
                        <GelLabel
                            marginLeft={-sizeBaseUnit * 2}
                            marginRight={-sizeBaseUnit * 2}
                        >Files</GelLabel>
                        <GelTag success small
                            style={{ float: "left" }}> {files.length}</GelTag>
                    </GelBoxLayout>
                }
                {(isExpandFiles) &&
                    <div>
                        {files.map((file, index) => (
                            <GelRow
                                style={{
                                    paddingLeft: sizeBaseX16,
                                    paddingBottom: sizeBaseX2,
                                    paddingTop: sizeBaseX2,
                                }}
                                key={Math.random()}
                            >
                                <GelCol
                                    style={{
                                        maxWidth: "3%",
                                        textAlign: "left",
                                    }}
                                >
                                    <GelRow style={{ paddingTop: sizeBaseX2 }}>
                                        {getIcon(file)}
                                    </GelRow>
                                </GelCol>
                                <GelCol>
                                    <GelRow>
                                        <GelCol
                                            style={{
                                                flex: 9,
                                                paddingLeft: 0,
                                                paddingTop: "10px"
                                            }}
                                        >
                                            <DocumentDownload documentIdentifier={file.sharePointDocumentIdentifier}
                                                displayName={file.name} />
                                        </GelCol>
                                        
                                        <GelCol
                                            style={{
                                                flex: 1,
                                            }}
                                        >
                                            {showBinIcon(file) && (
                                                <GelIcon
                                                    name="Bin"
                                                    id={`${file.name}.binicon`}
                                                    width={sizeBaseUnit * 4}
                                                    color={brandColorPrimary1}
                                                    onClick={() => handleDeleteFileClick(file)}
                                                />
                                            )}
                                        </GelCol>
                                    </GelRow>

                                    <GelRow>
                                        <div>{getMessage(file)}</div>
                                    </GelRow>
                                </GelCol>
                            </GelRow>
                        ))}
                    </div>

                }
                {reloadIcons && <div />}
                <input
                    type="file"
                    accept={AcceptedFileTypes}
                    ref={inputRef}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    aria-label="document"
                    multiple
                />
            </div>
        </>
    );
};


export default Uploader;




